import Mikeimg from './../../assets/Team/mike.jpg'
import Mikevcf from '../../assets/Business Cards/mike.vcf';
import Tyrusimg from './../../assets/Team/tyrus.jpg'
import Tyrusvcf from '../../assets/Business Cards/tyrus.vcf';
import Josephimg from './../../assets/Team/joe.jpg'
import Josephvcf from '../../assets/Business Cards/joseph.vcf'
import Mattimg from './../../assets/Team/matt.jpg'
import Mattvcf from '../../assets/Business Cards/matt.vcf'
import Stephenimg from './../../assets/Team/stephen.jpg'
import Stephenvcf from '../../assets/Business Cards/stephen.vcf'
import Nishmaimg from './../../assets/Team/nishma.jpg'
import Nishmavcf from '../../assets/Business Cards/nishma.vcf'
import Jeffvcf from '../../assets/Business Cards/jeff.vcf'
import Jeffimg from './../../assets/Team/jeff.jpg'

export const info = [
    {
      path: 'mike',
      name:'Mike Hall',
      title: 'President | Owner',
      cell:'985-640-4369',
      email:'mike@SeaLandMFG.com',
      img: Mikeimg,
      vcf: Mikevcf,
      careers:false
  },
  {
    path: 'tyrus',
    name:'Tyrus Smith',
    title: 'Vice President',
    cell:'985-696-5169',
    email:'smith@SeaLandMFG.com',
    img: Tyrusimg,
    vcf: Tyrusvcf,
    careers:true
}, 
  {
    path: 'matt',
    name: 'Matt Champagne',
    title: 'Manufacturing Manager',
    email: 'Matt@SeaLandMFG.com',
    cell: '985-677-2589',
    img: Mattimg,
    vcf: Mattvcf,
    careers:true
  },
 
  {
    path: 'joseph',
    name: 'Joseph Hagensee',
    title: 'Lead Engineer | CNC Programmer',
    email: 'Joseph@SeaLandMFG.com',
    cell: '504-515-6064',
    img: Josephimg,
    vcf: Josephvcf,
    careers:true
  },
  {
    path: 'stephen',
    name: 'Stephen Baay',
    title: 'Lead Research & Development',
    email: 'Steve@SeaLandMFG.com',
    cell: '504-400-8343',
    img: Stephenimg,
    vcf: Stephenvcf,
    careers:false,
  },
 
   {
    path: 'nishma',
    name: 'Nishma Maskey',
    title: 'Lead Front End Developer',
    email: 'Nishma@SeaLandMFG.com',
    cell: '320-266-4261',
    img: Nishmaimg,
    vcf: Nishmavcf,
    careers:false,
  },  
  {
    path: 'jeff',
    name: 'Jeff Wallace',
    title: 'Product Development Manager',
    email: 'Jeff@SeaLandMFG.com',
    cell: '225-936-1967',
    img: Jeffimg,
    vcf: Jeffvcf,
    careers:false
  },  
  {
    path: 'tim',
    name: 'Tim  ',
    email: 'tim@SeaLandMFG.com',
    img: Jeffimg,
    careers:false
  },  
  {
    path: 'ben',
    name: 'Ben',    
    email: 'ben@SeaLandMFG.com',
    img: Jeffimg,
    careers:false
  },  
    
  ];
  
  export default info ;
   
