import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';
import aboutimg from '../assets/about.gif'

import ImgOpt from './ImgOpt'

const useStyles = makeStyles((theme) => ({
  text: {
    color: '#231f20',
    margintop: '40px',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
  },
  bg: {
    backgroundColor: '#d6d9dd',
    padding: '80px',
    paddingBottom: '100px',
    [theme.breakpoints.down("md")]: {
      padding: '20px',
    },
  },
  aboutimg: {
    height: '400px',
    display: 'block',
    margin: 'auto',
    [theme.breakpoints.down("lg")]: {
      height: '270px',
    },
    [theme.breakpoints.down("sm")]: {
      height: '140px',
    },
  },
  pagecontent: {
    fontFamily: "Poppins, sans-serif",
    fontSize: '16px',
    padding: '10px'
  },
  [theme.breakpoints.down("md")]: {
    fontSize: '12px',
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <Grid id="about" className={classes.bg} style={{ display: 'flex' }} >
      <div style={{ flexGrow: '1' }}>

        <Container maxWidth="xl" component="main" >
          <Typography component="h2" align="center" className={classes.text} >
            ABOUT US
          </Typography>
          <Grid container spacing={2} alignItems="center" justify="center">

            <Grid item sm={12} lg={6} >
              <ImgOpt src={aboutimg} fallbacksrc={aboutimg} className={classes.aboutimg} alt="About Sea-Land Manufacturing" />
            </Grid>

            <Grid item sm={12} lg={6}>

              <Typography component={'span'} >

                <p className={classes.pagecontent}> Sea-Land Distributors was established in 2004 as a wholesale distributor of rigging products primarily for the offshore oil industry. In 2009, a second company named Sea-Fit was created to develop a line of mooring sockets used to secure drilling rigs in deep water. Soon after, Sea-Fit began adding products such as domestic safety anchor shackles up to 400 tons capacity, and a patented line of wide-body type heavy lift shackles called Super Shackles. After 2 years of development, Sea-Fit introduced a new resin socketing product in 2010 called Socket-Lock that continues to be distributed around the world today. In 2016, Sea-Fit was sold. All energy and attention was turned to Sea-Land Distributors to design, develop, and manufacture lines of products that are used not only in the offshore industry, but in many other industries and market sectors across North America.<br />With a substantial investment made in new manufacturing equipment to bolster our existing CNC machines, Sea-Land has trademarked several brands of products that are made at its facility in New Orleans. More brands and products are soon to come. In addition to the products it manufactures in-house, Sea-Land has also worked with some of its key suppliers to develop brands of products having improved features and capabilities than those existing in the market. Through our experience manufacturing precision parts with Swiss-Type lathes, waterjet cutting, and conventional milling & turning, Sea-Land Manufacturing was born to fill the needs of customers who require precision parts that are suited to the unique capabilities of Swiss-Type turning, or our other manufacturing equipment. Always looking to innovate, the team at Sea-Land is now positioned to write a new and bigger chapter as it continues to look forward…. always forward!</p>
              </Typography>
            </Grid>
          </Grid>
        </Container>

      </div>
    </Grid>

  );
}


export default Contact;