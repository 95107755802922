import React from 'react'
import ReactPlayer from "react-player"
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    text: {
        color: "#231f20",
        fontFamily: "Poppins, sans-serif",
        fontWeight: 600,
        fontSize: "50px",
        marginBottom: '40px',
        [theme.breakpoints.down("sm")]: {
            fontSize: "35px",
        }
    },
    bg: {
        padding: '40px'
    },

}));

export default function Video() {
    const classes = useStyles();
    return (
        <div style={{margin:'60px 50px'}}>
            <Container maxWidth="lg" className={classes.bg}>
                <Typography component="h2" align="center" className={classes.text} >
                    Videos
                </Typography>

                <Grid container spacing={2} justify="center" alignItems="center">
                    <Grid item xs={12} sm={12} md={6} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=K-zMtLi_oOw"
                            width={'295px'} height={'160px'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                        <ReactPlayer
                            url="https://www.youtube.com/watch?v=MvbFK1I_l7k"
                            width={'295px'} height={'160px'}
                        />
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
