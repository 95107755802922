import React from 'react'
import JotformEmbed from 'react-jotform-embed';
import { Container, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import './../App.css'


const useStyles = makeStyles(theme => ({
  pagetitle:{
    color: '#231f20',
    paddingTop:'30px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
    fontSize:'55px',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px", 
    },
  },
}));

function Contactform() {
    const classes = useStyles();

    return (
      
        <div>
     
            <Container maxWidth="md" component="main" >
        <Typography conponent="h2" align="center"className={classes.pagetitle} >
          CONTACT FORM
        </Typography>       
      </Container>
      <Grid container>
             <JotformEmbed src="https://form.jotform.com/200904378276964" />
          </Grid>            
      <div style={{marginBottom: '50px'}}></div>   
       </div>
    )
}
export default Contactform