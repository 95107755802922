import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../assets/logo.svg'
import { Link } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
  padding: {
    paddingRight: 30,
    marginTop: '40px',
    cursor: "pointer",
  },
  reslogo:
  {
    height: '70px',
    margin: '10px',
  },
  logo:
  {
    height: '100px',
    margin: '10px',
    marginTop: '20px',
    marginLeft: '30px',
  },
  flex: {
    display: 'flex',
    backgroundColor: '#e4e4e4'
  },
  flex1: {
  },
  flex2: {
    marginLeft: 'auto',
    marginTop: '17px'
  },
  toplink:
  {
    fontSize: '24px',
    textDecoration: 'none',
    fontFamily: 'Poppins, sans-serif',
    color: '#091a2d',
    margin: 'auto',
    padding: '10px',
    '&:hover': {
      background: "lightgrey",
    },
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: '25px',
    paddingBottom: '5px'
  }
});

function Test2() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>
        <Grid className={classes.flex} id="top" position="sticky" >
          <Grid className={classes.flex1}>
            <Link to="/">
              <img src={logo} className={classes.logo} alt="" /></Link>
          </Grid>
          <Grid className={classes.flex2}>
            <Toolbar position="sticky" >
              <Typography component={'span'} className={classes.padding} color="inherit" >
                <Link to="/" className={classes.toplink}>Home <HomeIcon className={classes.icon} /> </Link></Typography>

              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#capabilities" className={classes.toplink}>Capabilities <SettingsIcon className={classes.icon} /></a></Typography>

              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#about" className={classes.toplink}>About <InfoIcon className={classes.icon} /></a></Typography>


              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#contact" className={classes.toplink}>Contact <ContactSupportIcon className={classes.icon} /> </a></Typography>


            </Toolbar>
          </Grid>
        </Grid>
      </Box>
      {/* mobile  */}
      <Box display={{ xs: 'block', sm: 'none' }} >
        <Grid align="center" style={{ marginTop: '55px' }}>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Test2;