import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Typography, Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import MessageIcon from "@material-ui/icons/Message";
import Google from './../../assets/google-map.png';
import Googleb from './../../assets/webp/google-map.webp';
import ImgOpt from './../ImgOpt'

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  map: {
    height: "auto",
    maxWidth: "50px",
  },
  content: {
    padding: "60px 20px",
    textAlign: "center",
  },
  paper: {
    padding: "5px",
    marginBottom: "10px",
    backgroundColor:'#e4e4e4'
  },
  text: {
    color: "#231f20",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "30px",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  form: {
    width: "50%", // Fix IE 11 issue.
    marginTop: "5px",
  },
  submit: {
    margin: "5px",
    backgroundColor: "#213C54",
    padding: "10px",
    color: "white",
    fontSize: "14px",
    "&:hover": {
      color: "#213C54",
    },
  },
  mpagecontent: {
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
  },
  pagecontent: {
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
  },
  card: {
    backgroundColor:'white',
    borderRadius:'10px'
  },
});

function Cardcontact() {
  const classes = useStyles();
  return (
    <div style={{ display:'flex'}}>   
   
        <div  style={{flexGrow:'1'}}>
  
       
        <div className={classes.content}>
          <Container maxWidth="md">
            <Grid container spacing={4} alignItems="center" justify="center" className={classes.card}>
              <Grid item md={6}>
                {/* mobile */}
                <Box display={{ xs: "block", sm: "none" }}>
                  <Typography
                    component={"span"}
                    align="center"
                    className={classes.pagecontent}
                  >
                    {" "}
                    Sea-Land Distributors, LLC
                  </Typography>
                </Box>
                {/* except mobile */}
                <Box display={{ xs: "none", sm: "block" }}>
                  <Typography
                    component={"span"}
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                    align="center"
                    className={classes.pagecontent}
                  >
                     Sea-Land Manufacturing
                  </Typography>
                </Box>
                {/* mobile */}
                <Box display={{ xs: "block", sm: "none" }}>
                  <Typography
                    component={"span"}
                    align="center"
                    className={classes.mpagecontent}
                  >
                    1000 Edwards Avenue, Suite C <br />
                  </Typography>
                  <Typography
                    component="h2"
                    className={classes.mpagecontent}
                    align="center"
                  >
                    New Orleans, LA 70123 USA {" "}
                  </Typography><br/>
                  <Typography
                    component="h2"
                    align="center"
                    className={classes.mpagecontent}
                  >
                    <strong>  {" "}Hours: </strong> Monday-Friday{" "}
                  </Typography>
                  <Typography
                    component="h2"
                    align="center"
                    className={classes.mpagecontent}
                  >
                    8 a.m. - 5 p.m. CT
                  </Typography>
                </Box>
                {/* except mobile */}
                <Box display={{ xs: "none", sm: "block" }}>
                  <Typography
                    component={"span"}
                    align="center"
                    className={classes.pagecontent}
                  >
                    1000 Edwards Avenue, Suite C <br />
                  </Typography>
                  <Typography
                    component={"span"}
                    className={classes.pagecontent}
                    align="center"
                  >
                    New Orleans, Louisiana 70123 USA
                  </Typography>
                  <Typography
                    component="h2"
                    align="center"
                    className={classes.pagecontent}
                  >
                    <strong>Hours:</strong> Monday-Friday 8 a.m. - 5 p.m. CT
                  </Typography>
                </Box>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.google.com/maps/place/Sea-Land+Distributors,+LLC./@29.955757,-90.193528,15z/data=!4m5!3m4!1s0x0:0xacc2bccf2da70d2d!8m2!3d29.955757!4d-90.193528"
                >
                   <ImgOpt src={Googleb} fallbacksrc={Google}  className={classes.map} alt="Sea-Land Distributors Map"/>
                </a>
              </Grid>

              <Grid item md={6}>
                <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                  className={classes.pagecontent}
                  style={{ padding: "10px" }}
                >
                  <Grid item xs={12} sm={6} md={5}>
                    <a href="tel:504-736-9477" className={classes.link}>
                      <Paper className={classes.paper}>
                        <CallIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "14px" }}>504-736-9477</span>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <a
                      href="mailto:info@sealandmfg.com"
                      className={classes.link}
                    >
                      <Paper className={classes.paper}>
                        <MailOutlineIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "13px" }}>
                          info@SeaLandMFG.com
                        </span>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6} md={10}>
                    <Link to="/contactform" style={{ textDecoration: "none" }}>
                      <Paper className={classes.paper}>
                        <MessageIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "15px" }}>
                          Send us a Message
                        </span>
                      </Paper>
                    </Link>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>

    </div>
    </div>
  );
}

export default Cardcontact;
