import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Typography, Paper } from "@material-ui/core";
import info from "./team/Peopleinfo";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import MessageIcon from "@material-ui/icons/Message";
import styles from "./team/TeamMemberStyles";
import JotformEmbed from 'react-jotform-embed';

export default function Careers() {
  const classes = styles();

  const newTeam = info.filter((info) => info.careers === true);
  return (
    <Grid >
      <Container maxWidth="md" component="main" style={{marginBottom:'50px'}}>
        <Typography
          component="h2"
          align="center"
          className={classes.pageTittle}
        >
          Careers
        </Typography>
      <Grid container spacing={6} style={{margin:'20px 0'}} >
      <JotformEmbed src="https://form.jotform.com/230385973960062" />
      </Grid>
        {newTeam.map((member) => (
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
            className={classes.card} style={{ marginBottom:"25px" }}
          >
            <Grid item xs={12} md={6} sm={6} xl={6} align="center">
              <img src={member.img} alt={member.name} className={classes.img} />
            </Grid>
            <Grid item xs={12} md={6} sm={6} xl={6}>
              <p className={classes.name}>{member.name}</p>
              <p className={classes.title}> {member.title} </p>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                className={classes.pagecontent}
                style={{ padding: "10px" }}
              >
                <Grid item xs={12} sm={6} md={5}>
                  <a href={`tel:${member.cell}`} className={classes.link}>
                    <Paper className={classes.paper}>
                      <CallIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {member.cell}
                      </span>
                    </Paper>
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={5}>
                  <a href={`mailto:${member.email}`} className={classes.link}>
                    <Paper className={classes.paper}>
                      <MailOutlineIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span
                        style={{
                          fontSize: "12px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        {member.email}
                      </span>
                    </Paper>
                  </a>
                </Grid>
                <Grid item xs={12} sm={6} md={10}>
                  <a href={member.vcf} className={classes.link}>
                    <Paper className={classes.paper}>
                      <MessageIcon style={{ verticalAlign: "middle" }} />
                      <br />
                      <span
                        style={{
                          fontSize: "15px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Add to Contacts
                      </span>
                    </Paper>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Container>
    </Grid>
  );
}
