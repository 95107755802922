import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Typography } from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import CitizenL from '../assets/citizen_l32.jpg';
import CitizenA from '../assets/citizen_A20.png';
import Hyundai from '../assets/Hyundai.jpg'
import Hwacheon from '../assets/Hwacheon.jpg'
import KF5600 from '../assets/KF5600.jpg'
import VT from '../assets/VT-650.jpg'
import Optimax from '../assets/optimax.jpg'
import OrbitForm from '../assets/OrbitForm.jpg'
import Laser from '../assets/laser.jpg'
import NDT from '../assets/ndt.jpg'
import ModalImage from "react-modal-image";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  content: {
    padding: "60px 20px",
    textAlign: "center",
  },
  text: {
    color: "#231f20",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "50px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "35px",
    },
  },
  subtitle: {
    color: "#231f20",
    marginTop: "20px",
    marginBottom: "20px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "25px",
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: "20px",
  },
  link: {
    textDecoration: "none",
    color: "black",
  },
  liststyle: {
    listStyleType: 'square',
    paddingLeft: '40px'
  },
  bg: {
    backgroundColor: '#d6d9dd',
    padding: '40px'
  },
  nobg: {
    padding: '40px'
  },
  icon: {
    verticalAlign: 'middle',
    fontSize: '20px',
    paddingBottom: '5px'
  }

}));


function Contact() {
  const classes = useStyles();
  return (
    <div id="capabilities" style={{ display: 'flex', paddingTop: "20px" }}>
      <div style={{ flexGrow: '1' }}>

        {/* 1 */}
        <Container maxWidth="xl" className={classes.nobg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Citizen A20 (Qty. 6)
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Swiss-Type CNC Lathe
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={CitizenA}
                  large={CitizenA} hideDownload={true} hideZoom={true}
                  className={classes.card} alt=" Citizen A20 (Qty. 6)"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li>Up to 20mm diameter stock </li>
                  <li>Up to 12 ft part lengths with automatic bar feeder</li>
                  <li> Highest precision turning method available due to sliding stock, ground, carbide-lined guide bushing, and rigid tooling gangs  </li>
                  <li> 5 Axis turning capabilities</li>
                  <li>10,000 RPM spindle for machining small diameters </li>
                  <li> 6000 RPM Live tooling for cross drilling, milling, tapping, and slotting operations</li>
                  <li> Sub Spindle and back work tooling allows for complete part in 1 cycle</li>
                  <li>Simultaneous front and back machining decreases cycle times</li>
                  <li><a href="https://www.youtube.com/watch?v=QDTySdxt5oQ" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li><a href="https://www.youtube.com/watch?v=K-zMtLi_oOw" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li><a href="http://www.marucit.com/products/a20.html" target="_blank">Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 2 */}
        <Container maxWidth="xl" className={classes.bg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Citizen L32
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Swiss-Type CNC Lathe
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li> Up to 38mm diameter stock               </li>
                  <li> Up to 12 ft part lengths with automatic bar feeder</li>
                  <li> Highest precision turning method available due to sliding stock, ground, carbide-lined guide bushing, and rigid tooling gangs</li>
                  <li>9 Axis turning, with B axis, and back tool post Y Axis. Capable of ALL axis simultaneous machining allowing for extremely complex shapes</li>
                  <li>8,000 RPM spindle for machining small diameters </li>
                  <li> 6,000 RPM Live tooling for cross drilling, milling, tapping, and slotting operations on both front and back work.</li>
                  <li> Sub Spindle and back work tooling allows for complete part in 1 cycle</li>
                  <li>Simultaneous front and back machining decreases cycle times</li>
                  <li><a href="https://www.youtube.com/watch?v=oJO_9Ja6Nbc" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li><a href="http://www.marucit.com/products/l32.html" target="_blank">Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={CitizenL}
                  large={CitizenL} hideDownload={true} hideZoom={true}
                  className={classes.card} alt=" Citizen L32"
                />
              </Grid>
            </Grid>
          </Container>
        </Container>


        {/* 3 */}
        <Container maxWidth="xl" className={classes.nobg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Hyundai L2100SY
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Multi-Tasking Turning Center
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">

              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={Hyundai}
                  large={Hyundai} hideDownload={true} hideZoom={true}
                  className={classes.card} alt="    Hyundai L2100SY"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li>Up to 2.6 in. diameter by 5 ft. long bar-fed stock, and 20 ft long manually fed stock </li>
                  <li>13.2 in. max turning diameter</li>
                  <li>17.9 in. max turning length</li>
                  <li>	Live tooling on X and Y Axis</li>
                  <li> Y-Axis</li>
                  <li><a href="https://www.youtube.com/watch?v=xCtpPWmkfmc&feature=youtu.be" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li> <a href="https://www.techspex.com/turning-machines/hyundai-wia(2489)/5119" target="_blank">Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                  

                </ul>
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 4 */}
        <Container maxWidth="xl" className={classes.bg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Hwacheon Vesta-2000
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Large Vertical Machining Center
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={10}>
                <ul className={classes.liststyle}>
                  <li> Large 78.75 in. long x 33.5 in. wide x  31.5 in. tall  X / Y / Z Axis Stroke and table size</li>
                  <li>High-Feed Trochoidal Milling passes for increased surface finish quality</li>
                  <li>10,000 RPM Spindle</li>
                  <li>	High Pressure coolant</li>
                  <li>	Prepped for 4 Axis machining via rotary table</li>                 
                  <li><a href="https://www.youtube.com/watch?v=6zAmbdTMMWY" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li><a href="https://www.hwacheon-europe.com/en/Vertical-Machining-Centers/VESTA-2000" target="_blank">Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <ModalImage
                  small={Hwacheon}
                  large={Hwacheon} hideDownload={true} hideZoom={true}
                  className={classes.card} alt=" Hwacheon Vesta-2000"
                  style={{ width: '100px' }}
                />
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 5 */}
        <Container maxWidth="xl" className={classes.nobg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Hyundai KF5600
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Vertical Machining Center
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">

              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={KF5600}
                  large={KF5600} hideDownload={true} hideZoom={true}
                  className={classes.card} alt="  Hyundai KF5600"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li>Ball-Lock quick change fixturing          </li>
                  <li>8,000 RPM Spindle with high rigidity</li>
                  <li>High-Feed Tricordial Milling passes for increased surface finish quality</li>
                  <li><a href="https://www.youtube.com/watch?v=MvbFK1I_l7k" target="_blank">Additional Video</a> <OpenInNewIcon className={classes.icon} /></li>
                  <li><a href="https://machine.hyundai-wia.com/eu/product/kf5600.asp" target="_blank">Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 6 */}
        <Container maxWidth="xl" className={classes.bg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Hwacheon VT-650
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Vertical Turning Center
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={8} lg={10}>
                <ul className={classes.liststyle}>
                  <li> 31.5 in. max turning diameter</li>
                  <li>29.5 in. max part height</li>
                  <li>	Y-Axis</li>
                  <li>	Capable of turning, milling, drilling broaching parts</li>

                  <li><a href="https://www.hwacheon-europe.com/en/Vertical-Turning-Centers/VT-650" target="_blank">
                    Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={2}>
                <ModalImage
                  small={VT}
                  large={VT} hideDownload={true} hideZoom={true}
                  className={classes.card} alt=" Hwacheon VT-650"
                />
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 7 */}
        <Container maxWidth="xl" className={classes.nobg} >
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              OMAX Optimax 60X
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Dual Gantry Waterjet
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">

              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={Optimax}
                  large={Optimax} hideDownload={true} hideZoom={true}
                  className={classes.card} alt="OMAX Optimax 60X"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li>	5’ x 10’ plate capacity          </li>
                  <li> 	12” max cutting thickness</li>
                  <li>Can cut any material</li>
                  <li>Tilt-a-Jet technology reduces taper to hold .001” tolerances through the entire thickness of the part.</li>
                  <li> 100HP of cutting power</li>
                  <li> Dual Gantry to allow for 2 jets to operate independently and simultaneously for increased speeds</li>
                  <li>Dual Pumps for increased speed on single or dual jet cutting operations</li>
                  <li><a href="https://www.omax.com/optimax-waterjet/60x" target="_blank">
                    Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 8 */}
        <Container maxWidth="xl" className={classes.bg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              OrbitForm BT-750
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Pneumatic Orbital Flaring Machine
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li>¾” Diameter head forming capacity	 </li>
                  <li> 2.5” Maximum Stroke</li>
                  <li> Cold forms riveted connections in solid or hollow rivets</li>
                  <li>Multiple Head shapes such as domed, or flat available</li>
                  <li>Can form solid, pivoting, or sliding connections for various applications </li>
                  <li><a href="https://www.orbitform.com/standard-products/orbital-bench-machines/b-750" target="_blank">
                    Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={OrbitForm}
                  large={OrbitForm} hideDownload={true} hideZoom={true}
                  className={classes.card} alt=" OrbitForm BT-750"
                />
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 9 */}
        <Container maxWidth="xl" className={classes.nobg}>
          <Container maxWidth="lg">
            <Typography component="h2" align="center" className={classes.text} >
              Control Laser InstaMark
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Fiber Laser Marking and Engraving System
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">

              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={Laser}
                  large={Laser} hideDownload={true} hideZoom={true}
                  className={classes.card} alt="  Control Laser InstaMark"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li> 7” x 7” single setup marking area         </li>
                  <li>	Settings allow for surface finish removal, shallow etching, and deep etching. </li>
                  <li>On stainless steel and titanium, settings allow for various color and sheen markings </li>
                  <li>Common materials to mark, or etch include: Aluminum, Stainless Steel, Carbon Steel, Titanium, Plastics, and painted surfaces.</li>
                  <li>Text, logos, barcoding, and serialization marks available </li>
                </ul>
              </Grid>
            </Grid>
          </Container>
        </Container>

        {/* 10 */}
        <Container maxWidth="xl" className={classes.bg}>
          <Container maxWidth="lg">
            <Typography align="center" className={classes.text} >
              Modal Shop NDT-RAM
            </Typography>
            <Typography component="h2" align="center" className={classes.subtitle}>
              Acoustic Resonance Method NDT System
            </Typography>
            <Grid container spacing={2} justify="center" alignItems="center">
              <Grid item xs={12} sm={12} md={8}>
                <ul className={classes.liststyle}>
                  <li> Performs to ASTM-E2001 Resonance Acoustic Method NDT Standards        </li>
                  <li>Detects virtually all types of defects in a part when comparing to a statistical reference part.</li>
                  <li>High Throughput</li>
                  <li>	No Part Preparation - no need for cleaning, magnetizing, dye or other preparation</li>
                  <li>Accurate - computerized Pass/Fail acceptance ranges ensure inspection accuracy</li>
                  <li>Report Generation - a detailed or summary report, including statistical analysis, is easily generated</li>
                  <li><a href="https://www.modalshop.com/ndt/Advantages-of-Resonant-Acoustic-Method?ID=106" target="_blank">
                    Full Machine Specs</a> <OpenInNewIcon className={classes.icon} /></li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <ModalImage
                  small={NDT}
                  large={NDT} hideDownload={true} hideZoom={true}
                  className={classes.card} alt="  Modal Shop NDT-RAM"
                />
              </Grid>
            </Grid>
          </Container>
        </Container>
      </div>
    </div>
  );
}

export default Contact;
