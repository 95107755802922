import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Link} from 'react-router-dom';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'
import logo from '../assets/logo.svg'
import invlogo from '../assets/invlogo.svg'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import HomeIcon from '@material-ui/icons/Home';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import InfoIcon from '@material-ui/icons/Info';
import { Grid } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  // root: {    
  //   [theme.breakpoints.down('sm')]: {
  //     display: 'none',
  //   },    
  // },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },  
    padding: {
      paddingRight: 30,
      marginTop: '10px',
      cursor: "pointer",
    },
    reslogo:
    {
      height: '70px',
    },
    logo:
    {
      height: '55px',
      margin: '10px',
      marginTop: '10px',
      marginBottom: '5px',
      marginLeft: '30px',
    },
    flex: {
      display: 'flex',
     
    },
    flex2: {
      marginLeft: 'auto',
    },
    toplink:
    {
      fontSize: '24px',
      textDecoration: 'none',
      fontFamily: 'Poppins, sans-serif',
      color: '#fff',
      margin: 'auto',
      padding: '10px',
      '&:hover': {
        color: "lightgrey",
      },
    },
    icon: {
      verticalAlign: 'middle',
      fontSize: '25px',
      paddingBottom: '5px'
    }
    
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,  
  window: PropTypes.func,
};

function ElevateAppBar(props) {
  const classes = useStyles();
 

  return (    
    <React.Fragment>
      {/* <Topnav/> */}
     
       <ElevationScroll {...props}> 
      <AppBar className={classes.root} position="sticky" style={{ backgroundColor: '#091a2d', width:'100%'}}>        
      
        <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>
        <Grid className={classes.flex} id="top" position="sticky" >
          <Grid className={classes.flex1}>
            <Link to="/">
              <img src={invlogo} className={classes.logo} alt="" /></Link>
          </Grid>
          <Grid className={classes.flex2}>
            <Toolbar position="sticky" >
              <Typography component={'span'} className={classes.padding} color="inherit" >
                <Link to="/" className={classes.toplink}>Home <HomeIcon className={classes.icon} /> </Link></Typography>

              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#capabilities" className={classes.toplink}>Capabilities <SettingsIcon className={classes.icon} /></a></Typography>

                <Typography component={'span'} className={classes.padding} color="inherit" >
                <Link to="/quality" className={classes.toplink}>Quality <CheckCircleIcon className={classes.icon} /> </Link></Typography>

              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#about" className={classes.toplink}>About <InfoIcon className={classes.icon} /></a></Typography>


              <Typography component={'span'} className={classes.padding} color="inherit" >
                <a href="/#contact" className={classes.toplink}>Contact <ContactSupportIcon className={classes.icon} /> </a></Typography>


            </Toolbar>
          </Grid>
        </Grid>
      </Box>  
    
      </AppBar>
      </ElevationScroll>    
      
      
    </React.Fragment>
  );
};


export default ElevateAppBar;