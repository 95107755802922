import React from 'react'
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import XQ from '../assets/XQ.png'
import FiveA from '../assets/5A_Assurance.png'
import XQCertified from '../assets/XQ_Certified.png'

const useStyles = makeStyles(theme => ({
    pagetitle: {
        color: '#231f20',
        padding: '30px',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 600,
        fontSize: '55px',
        [theme.breakpoints.down("md")]: {
            fontSize: "35px",
        },
    },
    pagesubtitle: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: '25px',
        [theme.breakpoints.down("md")]: {
            fontSize: "23px",
        },
    },
    pageContent: {
        padding: '30px',
        fontSize: '18px',
        fontFamily: "Poppins, sans-serif",
    },
    img: {
        width: '200px',
        height: 'auto',
        [theme.breakpoints.down("md")]: {
            width: '100px',
        },
    },
    liststyle: {
        listStyleType: 'square',
        paddingLeft: '40px',
        fontSize: '18px',
        padding: '30px',
    },
    liststyle2: {
        listStyleType: 'none',
        paddingLeft: '40px',
        fontSize: '18px',
        padding: '10px',
    },

}));

export default function Quality() {
    const classes = useStyles();
    return (
        <Container maxWidth="lg" component="main" >
            <Typography component="h2" align="center" className={classes.pagetitle} >
                XQ Quality Assurance
            </Typography>
            <Typography component="h2" className={classes.pagesubtitle}>
                What is XQ Quality Assurance?
            </Typography>
            <Typography component="h2" className={classes.pageContent}>
                XQ Assurance is a quality program developed by Sea-Land Manufacturing and consists of programs for the systematic monitoring and evaluation of the various aspects of products or facilities to ensure that the standards of quality are being met.
            </Typography>

            <Typography component="h2" className={classes.pagesubtitle}>
                What XQ  Quality Assurance Programs Do
            </Typography>
            <Typography component="h2" className={classes.pageContent}>
                Created programs are based on established and recognized standards that when followed, VERIFY that procedures are followed and testing equipment is in compliance and calibrated.  Once verification is complete, products are tested to ensure they QUALIFY according to the standards established.  After products are qualified, XQ Assurance can then CERTIFY that they are worthy of the XQ Certified tag.
            </Typography>

            <Typography component="h2" className={classes.pagesubtitle}>
                What The XQ Certified Tag Means
            </Typography>
            <img src={XQ} alt="XQ" className={classes.img} />
            <Typography component="h2" className={classes.pageContent}>
                Products that are XQ Certified and carry the XQ tag mean those products have been designed, tested and proven to perform well in their intended use and are engineered with specifications for quality that meet or exceed what is common.
            </Typography>
            <Typography component="h2" className={classes.pagesubtitle}>
                XQ-5A Certified Products
            </Typography>
            <img src={FiveA} alt="XQ" className={classes.img} style={{ paddingRight: '5px' }} />
            <img src={XQCertified} alt="XQ" className={classes.img} />

            <ul className={classes.liststyle}>
                <li> Design Assurance </li>
                <li>Standards Assurance</li>
                <li>Facility Assurance </li>
                <li>Batch Assurance </li>
                <li>Individual Item Assurance</li>
            </ul>

            <Typography component="h2" className={classes.pageContent}>
                To become an XQ Certified product, the product must pass an assessment of the product design which includes a review of the drawing designs and technical documents.  Depending on the design requirements, the product(s) may also undergo fatigue, destructive and salt-spray testing.
                <br /><br />
                XQ Certified products must also meet the minimum requirements of applicable industry standards.  For example, screw pin anchor shackles must meet the requirements of ASME B30.26 and U.S. Federal Specification RRC-271-C.  XQ Assurance ensures that those standards are met and complied with.
                <br /><br />
                XQ Certified products must be produced in facilities that have been audited and approved to establish that each facility continuously and consistently follows quality processes to meet established XQ Standards for manufactured parts.
                <br /><br />
                As batches of products or components are produced, XQ Certified products must be tested to assure compliance with XQ Standards. Batches of products must be checked for chemistry of material grade, mechanical properties, physical properties and dimensional accuracy.
                <br /><br />
                When required, XQ Certified products are proof load tested and non-destructive tested.
            </Typography>

            <Typography component="h2" className={classes.pagesubtitle}>
                XQ Standards Programs
            </Typography>

            <ul className={classes.liststyle2}>
                <li>XQS-1.00  Forgings</li>
                <ul className={classes.liststyle2}>
                    <li>XQ-1.01  Closed Die Forgings</li>
                    <li>XQ-1.02  Open Die Forgings</li>
                </ul>
                <li>XQS-2.00  Castings</li>
                <ul className={classes.liststyle2}>
                    <li>XQ-2.01  Sand Castings</li>
                    <li>XQ-2.02  Investment Castings</li>
                </ul>
                <li>XQS-3.00  Machined Parts</li>
                <li>XQS-4.00  Formed & Welded Parts</li>
                <li>XQS-5.00  Heat Treatment</li>
                <li>XQS-6.00  Testing</li>
                <ul className={classes.liststyle2}>
                    <li>XQS-6.01  Non-Destructive Testing</li>
                    <ul className={classes.liststyle2}>
                        <li>XQS-6.01.01  Magnetic Particle Inspection	</li>
                        <li>XQS-6.01.02  Ultrasonic Testing</li>
                        <li>XQS-6.01.03  Accoustic Resonant Testing</li>
                    </ul>
                    <li>XQS-6.02  Destructive Testing</li>
                    <ul className={classes.liststyle2}>
                        <li>XQS-6.02.01  Fatigue Testing</li>
                        <li>XQS-6.02.02  Tensile Testing</li>
                        <li>XQS-6.02.03  Proof Load Testing</li>
                    </ul>
                    <li>   XQS-6.03  Salt Spray Testing</li>
                    <li>XQS-6.04  Hardness Testing</li>
                    <li>XQS-6.05  Charpy Testing</li>
                    <li>XQS-6.06  Chemical Testing   </li>
                </ul>
                <li>   XQS-7.00  Finishes & Coatings</li>
                <ul className={classes.liststyle2}>
                    <li> XQS-7.01  Powder Coating</li>
                    <li>XQS-7.02  Zinc Plating</li>
                    <li>XQS-7.03  Hot dip galvanizing</li>
                    <li>	XQS-7.04  Yellow zinc chromate</li>
                    <li>XQS-7.05  Passivation of Stainless Steel</li>
                    <li>XQS-7.06  Geomet Coating</li>
                    </ul>
                    <li>XQS-8.00  Rigging Assemblies</li>
                    <ul className={classes.liststyle2}>
                        <li>XQS-8.01  Web Slings</li>
                        <li>XQS-8.02  Wire Rope Slings</li>
                        <li>XQS-8.03  Alloy Chain Slings</li>
                        <li>XQS-8.04  High Performance Synthetic Rope Slings</li>
                        <li>XQS-8.05  Crane Boom Pendants</li>
                    </ul>
                </ul>
           <br/><br/><br/><br/>
        </Container >
    )
}
