import React from 'react'



export default function Error  () {
  return (
  
    <div style={{height: '50vh', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'30px', textAlign:'center'}}>   Sorry, we couldn't find the page you're looking for.</div>
  )
}
