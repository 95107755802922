import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  text:{
    color: '#231f20',
    fontFamily: "Poppins, sans-serif",
    fontSize: "16px",
    paddingLeft:'10px'
  },  
  paper: {
    padding: "5px",
    marginBottom: "10px",
    backgroundColor:'#e4e4e4'
  },
  name:{
    color: '#231f20',
    fontFamily: "Poppins, sans-serif",
    fontSize: "40px",
    borderBottom:'5px solid #00be00',
    padding:'10px 0px',
    textAlign:'center',
    [theme.breakpoints.down("md")]: {
    fontSize: "30px",
  },
},
title:{
    color: '#231f20',
    fontFamily: "Poppins, sans-serif",
    fontSize: "25px",
    textAlign:'center',
    [theme.breakpoints.down("md")]: {
    fontSize: "25px",
  },
},
  link: {
    textDecoration: "none",
    color: "black",
    textAlign:'center'
  },
  card: {
    backgroundColor:'white',
    borderRadius:'10px'
  },
  img: {
    height: '350px',
    display:'block',
      margin:'auto',
    [theme.breakpoints.down("lg")]: {
      height: '220px',
    },
    [theme.breakpoints.down("md")]: {
      height: '260px',
    },
  },
  pageTittle: {
    color: "#231f20",
    margin: '40px 0 -40px 0',
    fontFamily: "Poppins, sans-serif",
    fontSize: "55px",    
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
      margin: '20px',
    },
  },
  pageSubtitle: {
    fontFamily: "Poppins, sans-serif",
    paddingBottom: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
 
}));