import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Video from '../assets/sldmfg.mp4'
import Capabilities from "./Capabilities";
import Drawer from "./Drawer";
import Contact from "./Contact";
import Videos from "./Video";
import About from "./About";
import { Box, Grid } from "@material-ui/core";
import BackToTop from "./Backtotop";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
// import { Helmet } from "react-helmet";
import aboutimg from '../assets/about.gif'


const useStyles = makeStyles((theme) => ({
  legend: {
    fontSize: "24px",
    color: "#fff",
    backgroundColor:'#091a2d',
    padding: "10px",
    position: "absolute",
    zIndex: "999",
    margin: "0 auto",
    left: 0,
    right: "-65%",
    bottom: "10%",
    textAlign: "center",
    width: "20%",
    borderRadius: "10px",
    border:'2px solid #00be00',
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
    },
    [theme.breakpoints.down("lg")]: {     
      width: "24%",
      bottom: "10%",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "18px",
      padding: "5px",
      bottom: "35%",
      width: "30%",
      border:'1px solid #00be00',
      right: '5%',
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
      bottom: "66%",
      width: "35%",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
      bottom: "80%",
      width: "40%",
    },
  },

  mainimg: {
    width:'100%',
    display:'block',
      margin:'auto',
    [theme.breakpoints.down("lg")]: {
      height: '20px',
    },
    [theme.breakpoints.down("sm")]: {
      height: '140px',
    },
  },
  toplink:
  {
    textDecoration: 'none',
    fontFamily: 'Poppins, sans-serif',
    color: '#fff',
    margin: 'auto',
    padding: '10px',
    '&:hover': {
      color: "lightgrey",
    },
  },
}));

function Home() {

  const classes = useStyles();

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    // top: 'calc(50% - 15px)',
    top: '5%',
    width: '3%',
    height: '100%',
    color:'#00be00',
    cursor: 'pointer',
    '&hover':{
      top:0,
      bottom:0,
      backgroundColor:'lightgrey',
      opacity:'.4',
      
    }
   
};
  return (
    <Grid >
      {/* <Helmet>
        <title>
          Sea-Land Distributors
        </title>
      </Helmet> */}
      <BackToTop />
       <Box display={{ xs: "block", sm: "none" }}>
         <img src={aboutimg} className={classes.mainimg} alt="About Sea-Land Manufacturing"/>   
           
         <Drawer />
      </Box>  
      <Box display={{ xs: "none", sm: "block" }}>   
      <video autoPlay loop muted
       style={{ 
        width: '100%'
       }}>
        <source
          src={Video}
          type="video/mp4"        
        />       
      </video>     
    </Box>
    <p className={classes.legend}><a href="/#capabilities" className={classes.toplink}>DISCOVER CAPABILITIES</a></p>
     <Capabilities/>
     <Videos/>
      <About />
      <Contact />
    </Grid>
  );
}

export default Home;
