import React, { useEffect }  from "react";
import {  BrowserRouter as Router,  Routes,  Route, useLocation } from "react-router-dom";
import Home from "./components/Home";
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Termsofuse from "./components/Termsofuse";
import Careers from "./components/Careers";
import Privacy from "./components/Privacy";
import Contactform from "./components/Contactform";
import Box from "@material-ui/core/Box";
import Drawer from "./components/Drawer";
import About from "./components/About";
import TeamMember from "./components/team/TeamMember";
import  { info } from './components/team/Peopleinfo'
import Error from './components/404.js'
import Quality from './components/Quality'


function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default function App() {
  return ( 
    <div>   
      
    <Router>
      <Navbar/>
      <Box display={{ md: "block", lg: "none" }}>
        <Drawer />
      </Box>
      {/* <Box display={{ xs: "none", sm: "block", md: "none" }}>
       
      </Box> */}
    <Routes>
    <Route  path="/" element={<Home/>}></Route>
       <Route path="/contact" >
          <Route path=":name" element={<TeamMember info={info} />} />
        </Route>       
        {/* <Route path="/design&develop" element={<DesignDevelop/>}></Route>
        <Route path="/manufacture" element={<Manufacture/>}></Route>
        <Route path="/distribute" element={<Distribute/>}></Route> 
        <Route path="/ourbrands" element={<Brands/>}></Route>*/}
        <Route path="/quality" element={<Quality/>}></Route> 
        <Route path="/contactform" element={<Contactform/>}></Route>
        <Route path="/about" element={<About/>}></Route>
        <Route path="/privacypolicy" element={<Privacy/>}></Route>
        <Route path="/termsofuse" element={<Termsofuse/>}></Route>
        <Route path="/careers" element={<Careers/>}></Route>
        <Route  path="*" element={<Error/>}></Route>
       </Routes>
       <Footer/>     
    </Router>
    </div>
  );
}

